






















































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import date from 'quasar/src/utils/date.js';;
import InlineBuildingEdit from '@/components/InlineBuildingEdit.vue';
import config from '@/config';
import levenshtein from 'js-levenshtein';

import '@/components/Vue2Leaflet';
import 'leaflet/dist/leaflet.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import * as L from 'leaflet';



@Component({
  components: {
    InlineBuildingEdit,
  },
})
export default class BuildingsManage extends Vue {
  public $route: any;
  public $q: any;
  public $store: any;

  public loading: boolean = true;
  public buildings: any = [];

  private editLevel: boolean = false;
  private editArea: boolean = false;

  private editValue: string = '';
  private editOldValue: string = '';
  private editBuildingAddress: string = '';
  private editBuildingTimezone: string = '';
  private editBuildingId: number = 0;
  private editLevelId: number = 0;
  private editAreaId: number = 0;
  private editCanDelete: boolean = false;
  private editAreaAnalyzerId: any = null;
  private levelImageFile: any = null;
  private levelDeleteImage: boolean = false;
  private editLevelMaximized: boolean = false;
  private editLevelGeojson: any = null;

  private currentLevel: any = null;
  private currentBuilding: any = null;

  private availableAnalyzers: any = [];
  private availableAnalyzersLoading: boolean = false;

  private mapUrl: string = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  private mapCRS: any = L.CRS.Simple;
  private mapInstance: any = null;
  private mapShapes: any = [];
  private levelAreaSelect: boolean = false;
  private levelAreaSelectShape: any = null;
  private levelAreaSelectId: any = null;
  private availableLevelAreas: any = [];

  public async mounted() {
    this.refresh();
  }

  @Watch('route')
  public refresh() {
    this.loading = true;
    this.$store.dispatch('loadBuildings')
    .then((response: any) => {
      this.buildings = response;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  private setupMapForLevel(level: any) {
    this.mapShapes = [];

    if (level.image === null || level.image === undefined) {
      return;
    }

    const lmap: any = this.$refs.lmap;
    const lmapInstance: any = this.mapInstance = lmap.mapObject;

    // clear mapinstance
    lmapInstance.eachLayer((layer: any) => lmapInstance.removeLayer(layer));

    const imageUrl = this.getLevelImageUrl;
    const imageBounds: L.LatLngBoundsExpression = [
      [0, 0],
      [level.image.size[1], level.image.size[0]]];
    const tiles1 = L.imageOverlay(imageUrl, imageBounds);
    tiles1.addTo(lmapInstance);
    // @ts-ignore
    L.PM.reInitLayer(tiles1);
    lmapInstance.pm.addControls({
      position: 'topleft',
      drawCircle: false,
      drawPolyline: false,
      drawCircleMarker: false,
    });
    lmapInstance.fitBounds(
      imageBounds,
      {
        animate: false,
      });


    // create shapes from the geojson if exists
    if (level.geojson) {
      this.loadLevelGeojson(level.geojson);
    }

    this.setupEventListeners();
  }

  private loadLevelGeojson(geojson: any | null) {
    this.mapShapes = [];
    if (geojson.type !== 'FeatureCollection') {
      // only feature collection are supported right now.
      return;
    }

    geojson.features.map((entry: any) => {
      if (entry.type !== 'Feature' || !entry.geometry) {
        return;
      }
      const area = this.$store.getters.getAreaFromId(entry.properties.areaId);
      let shape;

      switch (entry.geometry.type) {
        case 'Polygon':
          shape = new L.Polygon(
            this.swapGeoJSONCoordinates(entry.geometry.coordinates),
          );
          break;
        case 'Point':
          shape = new L.Marker([
            entry.geometry.coordinates[1],
            entry.geometry.coordinates[0],
          ]);
          break;
      }

      if (shape !== undefined) {
        shape.addTo(this.mapInstance);
        this.mapShapes.push({
          tooltip: shape.bindTooltip(area ? area.name : ''),
          areaId: area ? { label: area.name, value: area.id } : null,
          shape,
        });
      }

    });
  }

  private swapGeoJSONCoordinates(paths: any) {
    return paths.map(
      (path: any) => path.map(
        (xy: any) => [xy[1], xy[0]],
      ),
    );
  }

  private setupEventListeners() {
    this.mapInstance.on('pm:create', (e: any) => {
      const shape = e.layer as
        | L.Polygon
        | L.Marker
        | L.Rectangle
        | L.Circle
        | L.Polyline;
      const tooltip = shape.bindTooltip('');
      this.mapShapes.push({
        areaId: null,
        shape,
      });
      shape.on('click', () => {
        this.openPopupAreaSelectionForShape(shape);
      });
      this.openPopupAreaSelectionForShape(shape);
    });

    this.mapInstance.on('pm:remove', (e: any) => {
      const shape = e.layer as
        | L.Polygon
        | L.Marker
        | L.Rectangle
        | L.Circle
        | L.Polyline;
      this.mapShapes = this.mapShapes.filter((entry: any) => entry.shape !== shape);
    });
  }

  private generateLevelGeoJSON() {
    const features = this.mapShapes.map((entry: any) => {
      const geojson = entry.shape.toGeoJSON();
      const areaId = this.getShapeInformation(entry.shape).areaId.value;
      geojson.properties = { areaId };
      return geojson;
    });
    if (features.length === 0) {
      return null;
    }
    return {
      type: 'FeatureCollection',
      features,
    };
  }

  private getShapeInformation(shape: any) {
    return this.mapShapes.find((entry: any) => entry.shape === shape);
  }

  private openPopupAreaSelectionForShape(shape: any) {
    this.levelAreaSelect = true;
    this.levelAreaSelectShape = shape;
    this.levelAreaSelectId = this.getShapeInformation(shape).areaId;
  }

  @Watch('levelAreaSelectId')
  private updateCurrentShapeAreaId() {
    const shapeInfo = this.getShapeInformation(this.levelAreaSelectShape);
    shapeInfo.areaId = this.levelAreaSelectId;
    shapeInfo.shape.bindTooltip(this.levelAreaSelectId.label);
    this.generateLevelGeoJSON();
  }

  private addArea(
      name: string, buildingId: number, levelId: number) {
    this.loading = true;
    this.$store.dispatch('addArea', {
      buildingId, levelId, name,
    }).then((response: any) => {
      this.refresh();
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  private addLevel(
      name: string, buildingId: number) {
    this.loading = true;
    this.$store.dispatch('addLevel', {
      buildingId, name,
    }).then((response: any) => {
      this.refresh();
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  private addBuilding(name: string) {
    this.loading = true;
    this.$store.dispatch('addBuilding', {
      name,
    }).then((response: any) => {
      this.refresh();
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  private showEditLevel(level: any, building: any) {
    this.selectLevel(level.id);
    this.editCanDelete = level.areas.length === 0;
    this.editValue = this.editOldValue = level.name;
    this.editBuildingId = building.id;
    this.editLevelId = level.id;
    this.editLevel = true;
    this.levelImageFile = null;
    this.levelDeleteImage = false;

    this.availableLevelAreas = level.areas.map((entry: any) => {
      return {
        label: entry.name,
        value: entry.id,
      };
    });

    Vue.nextTick(() => {
      this.setupMapForLevel(level);
    });
  }

  private onEditLevel() {
    if (levenshtein(this.editValue, this.editOldValue) >= 3) {
      this.$q.dialog({
        title: this.$t('big_change_title'),
        message: this.$t('level_change_message'),
        stackButtons: true,
        cancel: {
          label: this.$t('level_change_cancel'),
          outline: true,
        },
        ok: {
          label: this.$t('big_change_ok'),
          flat: true,
          size: 'm',
          dense: true,
        },
      })
      .onCancel(() => {
        this.editLevel = false;
      })
      .onOk(() => {
        this._onEditLevel();
      });
    } else {
      this._onEditLevel();
    }
  }

  private _onEditLevel() {
    const buildingId: number = this.editBuildingId;
    const levelId: number = this.editLevelId;
    const name: string = this.editValue;
    const image: object = this.levelImageFile;
    const geojson: any = this.generateLevelGeoJSON();
    const opts: object = {
      buildingId, levelId, name, geojson,
    };
    if (this.levelDeleteImage === true) {
      opts['image'] = null;
    } else if (image !== null) {
      opts['image'] = image;
    }
    this.loading = true;
    this.$store.dispatch('editLevel', opts,
    ).then((response: any) => {
      this.refresh();
      this.editLevel = false;
    }).catch((error: any) => {
      this.editLevel = false;
      this.loading = false;
      this.globalError(error);
    });
  }

  private deleteCurrentLevel() {
    this.$q.dialog({
      title: this.$t('delete_title', [this.editValue]),
      message: this.$t('delete_message', [this.editValue]),
      html: true
    })
    .onOk(() => {
      const buildingId: number = this.editBuildingId;
      const levelId: number = this.editLevelId;
      this.$store.dispatch('deleteLevel', {
        buildingId, levelId,
      }).then((response: any) => {
        this.refresh();
        this.editLevel = false;
      }).catch((error: any) => {
        this.editLevel = false;
        this.loading = false;
        this.globalError(error);
      });
    });
  }

  private selectLevel(levelId: number) {
    for (const building of this.buildings) {
      for (const level of building.levels) {
        if (level.id === levelId) {
          this.currentLevel = level;
          this.currentBuilding = building;
          return level;
        }
      }
    }
  }

  get getLevelImageUrl() {
    return `${config.api_url}/organisation/building/${this.currentBuilding.id}/level/${this.currentLevel.id}/image`;
  }

  private showEditArea(area: any, level: any, building: any) {
    this.availableAnalyzers = [];
    this.availableAnalyzersLoading = true;

    this.$store.dispatch(
      'loadAnalyzers', {
        params: {
          outdoor: false,
        },
      },
    ).then((response: any) => {
      this.availableAnalyzers = response.filter((entry: any) => {
        return (entry.area_id === null) && (!entry.is_outdoor);
      }).map((entry: any) => {
        return {label: entry.serial, value: entry.id};
      });
      this.availableAnalyzersLoading = false;
    }).catch((error: any) => {
      this.availableAnalyzersLoading = false;
      this.globalError(error);
    });

    this.editCanDelete = true;
    this.editValue = this.editOldValue = area.name;
    this.editBuildingId = building.id;
    this.editLevelId = level.id;
    this.editAreaId = area.id;
    this.editArea = true;
    if (area.analyzer !== null) {
      this.editAreaAnalyzerId = {
        label: area.analyzer.serial,
        value: area.analyzer.id,
      };
    } else {
      this.editAreaAnalyzerId = null;
    }
  }

  private onEditArea() {
    if (levenshtein(this.editValue, this.editOldValue) >= 3) {
      this.$q.dialog({
        title: this.$t('big_change_title'),
        message: this.$t('area_change_message'),
        stackButtons: true,
        cancel: {
          label: this.$t('area_change_cancel'),
          outline: true,
        },
        ok: {
          label: this.$t('big_change_ok'),
          flat: true,
          size: 'm',
          dense: true,
        },
      })
      .onCancel(() => {
        this.editArea = false;
      })
      .onOk(() => {
        this._onEditArea();
      });
    } else {
      this._onEditArea();
    }
  }

  private _onEditArea() {
    const buildingId: number = this.editBuildingId;
    const levelId: number = this.editLevelId;
    const areaId: number = this.editAreaId;
    const name: string = this.editValue;
    let analyzerId: any = this.editAreaAnalyzerId;
    this.loading = true;

    if (analyzerId !== null) {
      analyzerId = analyzerId.value;
    }

    this.$store.dispatch('editArea', {
      buildingId, levelId, areaId, name, analyzerId,
    }).then((response: any) => {
      this.refresh();
      this.editArea = false;
    }).catch((error: any) => {
      this.editArea = false;
      this.loading = false;
      this.globalError(error);
    });
  }

  private deleteCurrentArea() {
    this.$q.dialog({
      title: this.$t('delete_title', [this.editValue]),
      message: this.$t('delete_message', [this.editValue]),
      html: true
    })
    .onOk(() => {
      const buildingId: number = this.editBuildingId;
      const levelId: number = this.editLevelId;
      const areaId: number = this.editAreaId;
      this.$store.dispatch('deleteArea', {
        buildingId, levelId, areaId,
      }).then((response: any) => {
        this.refresh();
        this.editArea = false;
      }).catch((error: any) => {
        this.editArea = false;
        this.loading = false;
        this.globalError(error);
      });
    });
  }

  private onLevelImageRejected() {
    // TODO
  }

}
