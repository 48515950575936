import { render, staticRenderFns } from "./InlineBuildingEdit.vue?vue&type=template&id=6d8ac15e&scoped=true&"
import script from "./InlineBuildingEdit.vue?vue&type=script&lang=ts&"
export * from "./InlineBuildingEdit.vue?vue&type=script&lang=ts&"
import style0 from "./InlineBuildingEdit.vue?vue&type=style&index=0&id=6d8ac15e&lang=scss&scoped=true&"
import style1 from "./InlineBuildingEdit.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d8ac15e",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QInput});
