





















import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';

@Component
export default class InlineBuildingEdit extends Vue {
  @Prop() public label!: string;
  public editing: boolean = false;
  public value: string = '';
  public complete: boolean = true;

  public checkAndValidate() {
    const inputName: any = this.$refs.name;
    inputName.validate();
    if (inputName.hasError) {
      return;
    } else {
      this.validate();
    }
  }

  @Emit()
  public validate() {
    this.editing = false;
    const value = this.value;
    this.value = '';
    return value;
  }
}
